import addDays from 'date-fns/addDays';
import { dateFnsConvert } from '../dateFnsConvert';

function getDueDate(date: Date | string, addDay?: number): string {
  const currentDate = new Date(date);

  const currentDateWithAddDay = addDays(currentDate, addDay || 0);
  currentDateWithAddDay.setHours(23);
  currentDateWithAddDay.setMinutes(0);
  currentDateWithAddDay.setSeconds(0);

  return dateFnsConvert.toDateTimeLocal(currentDateWithAddDay.toISOString());
}

export default getDueDate;
